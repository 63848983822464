import React from 'react'

const Footer = () => {
  return (
   

<footer class="bg-blue-900 dark:bg-gray-900 py-8">
    <div class="w-full max-w-screen-xl text-center text-white">
        <ul className='flex flex-wrap justify-center mb-2'>
            <li className='px-4 py-2 border-r-2'> <a href='#' className='hover:underline'> BMS Direct Admission 2024-25 </a> </li>
            <li className='px-4 py-2 border-r-2'><a href="#home" className='hover:underline'> Home </a> </li>
            <li className='px-4 py-2 border-r-2'> <a href="#contact-form" className='hover:underline'> Contact Us </a> </li>
            <li className='px-4 py-2'> <a href="#" className='hover:underline'> BMSIT B.Tech Admission 2024 </a> </li>
        </ul>
        <p class="block text-sm text-white sm:text-center dark:text-gray-400">© 2024 <a href="#" class="hover:underline">Copyrights</a>. All Rights Reserved.</p>
    </div>
</footer>


  )
}

export default Footer