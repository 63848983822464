import React from 'react'
import logo from '../../assets/images/BMS_College_of_Engineering.png'

const Header = () => {
    return (
        <div className='pt-20'>
            <div className='text-center mb-4 md:mb-8 flex flex-row'>
                <div className='inline-flex mx-auto'>
                    <img className='w-20 h-20' src={logo} href="" />
                    <div>
                        <h1 class="mb-2 pl-3 font-extrabold leading-none tracking-tight text-gray-900 md:text-2xl lg:text-4xl dark:text-white">BMS Direct Admission 2024 Management quota seats</h1>
                        <p class="mb-2 md:mb-6 pl-3 text-sm font-normal text-gray-500 lg:text-xl sm:px-16 xl:px-48 dark:text-gray-400">B.tech Admission in BMSCE</p>
                        <a href="#contact-form" class="inline-flex items-center text-sm p-3 justify-center md:px-5 md:py-3 md:text-base font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 dark:focus:ring-blue-900">
                            Contact Now
                            <svg class="w-3.5 h-3.5 ms-2 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5h12m0 0L9 1m4 4L9 9" />
                            </svg>
                        </a>
                    </div>

                </div>

            </div>

            <div class="flex justify-center text-sm hidden lg:block font-medium text-center text-gray-500 border-b border-gray-200 dark:text-gray-400 dark:border-gray-700">
                <ul class="flex flex-wrap -mb-px">
                    <li class="me-2">
                        <a href="#" class="inline-block p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300">BMS Direct Admission 2024-25</a>
                    </li>
                    <li class="me-2">
                        <a href="#home" class="inline-block p-4 text-blue-600 border-b-2 border-blue-600 rounded-t-lg active dark:text-blue-500 dark:border-blue-500" aria-current="page">Home</a>
                    </li>
                    <li class="me-2">
                        <a href="#contact-form" class="inline-block p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300">Contact Us</a>
                    </li>
                    <li class="me-2">
                        <a href="#" class="inline-block p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300">BMSIT B.Tech Admission 2024</a>
                    </li>
                </ul>
            </div>
        </div>

    )
}

export default Header