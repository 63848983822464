import 'flowbite';
import './App.css';
import Navbar from './components/Navbar/Navbar';
import Header from './components/Header/Header';
import Content from './components/Content/Content';
import Footer from './components/Footer/Footer';
import whatsapp from './assets/images/whatsapp-brands.svg';
import telephone from './assets/images/telephone-call.png';


function App() {
  return (
    <div>
      <div className='fixed bottom-0 right-0 m-2 md:my-10 md:mx-10 z-40'>
        <a href="https://wa.me/+918123908089" title="Chat On Whatsapp" class="block bg-gray-100 text-sm text-gray-900 rounded-full border-2 hover:bg-gray-200 md:hover:text-blue-700 md:p-0 md:dark:hover:text-blue-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700">
          <img className='w-10 h-10 p-1 md:w-16 md:h-16 md:p-2' src={whatsapp}  alt='whatsapp'/></a>
      </div>
      <div className='fixed bottom-0 left-0 m-2 md:my-10 md:mx-10 z-40'>
        <a href="tel:+918123908089" title="Call Us" class="block bg-gray-100 text-sm text-gray-900 rounded-full border-2 hover:bg-gray-200 md:hover:text-blue-700 md:p-0 md:dark:hover:text-blue-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700">
          <img className='w-10 h-10 p-1 md:w-16 md:h-16 md:p-2' src={telephone}  alt='telephone'/></a>
      </div>
      <Navbar/>
    <Header/>
    <Content/>
    <Footer/>
    </div>
    
    
    
  );
}

export default App;
