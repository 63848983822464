import React from 'react'
import college_image from '../../assets/images/BMS_College.png'

const Content = () => {

    const [result, setResult] = React.useState("");

    const onSubmit = async (event) => {
        event.preventDefault();
        setResult("Sending....");
        const formData = new FormData(event.target);

        formData.append("access_key", "c6fe0244-4fe4-4577-887b-597724f4184d");

        const response = await fetch("https://api.web3forms.com/submit", {
            method: "POST",
            body: formData
        });

        const data = await response.json();

        if (data.success) {
            setResult("Form Submitted Successfully");
            event.target.reset();
        } else {
            console.log("Error", data);
            setResult(data.message);
        }
    };



    return (
        <div class="flex flex-wrap lg:flex-nowrap">
            <div class="w-full lg:basis-4/6 text-left" id='home'>
                <div className='text-2xl font-bold mx-4 my-4 lg:mx-20 lg:my-10 leading-none tracking-tight text-blue-900 md:text-3xl lg:text-4xl dark:text-white'>
                    BMS Direct Admission 2024-25
                </div>
                <div>
                    <div className='text-xl mx-auto lg:mr-8 lg:ml-16 px-4 leading-none font-semibold tracking-tight text-blue-900 md:text-2xl lg:text-2xl dark:text-white'>
                        <div>
                            <img src={college_image} className='mb-4' alt="" />
                        </div>
                        BMS COLLEGE OF Engineering Admission in Btech (2024-25) <a href='tel:+918123908089' className='underline block'> Call +918123908089 </a>

                    </div>

                    <div className='mx-8 lg:mr-16 lg:ml-32'>
                        <p className='text-gray-700 my-4'>
                            BMS College of Engineering was founded in the year 1946. It is also the first private sector Engineering college in India.
                        </p>

                        <p className='text-gray-700 my-4'>
                            It has now completed its 73 years of success. The full form of BMS is Businayana Mukundadas Sreenivasaiah.BMS College of Engineering offers 13 UG and 16 PG courses. It also has a research and study center attached to the college.

                        </p>

                        <p className='text-gray-700 my-4'>
                            BMS Engineering College is also a NAAC A accredited college.  It is also accredited by NBA. There are more than 5000 students studying in the college and is also one of the highly populated engineering colleges in Karnataka.
                        </p>

                        <p className='text-gray-700 my-4'>
                            The college has an on-campus placement cell that will guide in career and other skills. It is been said that more than 100 reputed companies come in for taking students from every branch.
                        </p>

                        <p className='text-gray-700 my-4'>
                            After the first campus became a huge success, the BMS group also started a new campus in Yelahanka, Bangalore which is known as the BMS Institute of Technology. Both the colleges are run by one group or trust.
                        </p>

                        <div>
                            <h1 className='text-2xl text-blue-900 font-semibold'> Branches in BMS College of Engineering   </h1>
                            <p className='text-gray-700 my-4'>There are many departments available at BMS Engineering College. The list of branches available is listed below. The undergraduate branches available are:
                            </p>
                            <ul class="list-disc list-inside p-4 bg-white dark:bg-gray-900 rounded-lg shadow-lg max-w-lg">
                                <li class="text-gray-800 dark:text-gray-200 mb-2">B.Arch. Biotechnology</li>
                                <li class="text-gray-800 dark:text-gray-200 mb-2">B.E Computer Science</li>
                                <li class="text-gray-800 dark:text-gray-200 mb-2">B.E Telecommunication</li>
                                <li class="text-gray-800 dark:text-gray-200 mb-2">B.E Mechanical Engineering</li>
                                <li class="text-gray-800 dark:text-gray-200 mb-2">B.E Medical Electronics</li>
                                <li class="text-gray-800 dark:text-gray-200 mb-2">B.E Instrumentation Technology</li>
                                <li class="text-gray-800 dark:text-gray-200 mb-2">B.E Electronics and Communication</li>
                                <li class="text-gray-800 dark:text-gray-200 mb-2">B.E Industrial Engineering and Management</li>
                                <li class="text-gray-800 dark:text-gray-200 mb-2">B.E Electrical and Electronics Engineering</li>
                                <li class="text-gray-800 dark:text-gray-200 mb-2">B.E Civil Engineering</li>
                                <li class="text-gray-800 dark:text-gray-200 mb-2">B.E Information Science and Engineering</li>
                            </ul>
                        </div>

                        <div>
                            <p className='text-gray-700 my-4'>The postgraduation courses offered by BMS College of Engineering are:
                            </p>
                            <ul class="list-disc list-inside p-4 bg-white dark:bg-gray-900 rounded-lg shadow-lg max-w-lg">
                                <li class="text-gray-800 dark:text-gray-200 mb-2">Master of Business Administration</li>
                                <li class="text-gray-800 dark:text-gray-200 mb-2">Master of Computer Application</li>
                                <li class="text-gray-800 dark:text-gray-200 mb-2">M.Tech Machine Design</li>
                                <li class="text-gray-800 dark:text-gray-200 mb-2">M.Tech VLSI</li>
                                <li class="text-gray-800 dark:text-gray-200 mb-2">M.Tech Electronics</li>
                                <li class="text-gray-800 dark:text-gray-200 mb-2">M.Tech Manufacturing Science and Engineering</li>
                                <li class="text-gray-800 dark:text-gray-200 mb-2">M.Tech Digital Communication</li>
                                <li class="text-gray-800 dark:text-gray-200 mb-2">M.Tech Environmental Engineering</li>
                                <li class="text-gray-800 dark:text-gray-200 mb-2">M.Tech Construction Technology</li>
                                <li class="text-gray-800 dark:text-gray-200 mb-2">M.Tech Power Electronics</li>
                                <li class="text-gray-800 dark:text-gray-200 mb-2">M.Tech Habitat Design</li>
                                <li class="text-gray-800 dark:text-gray-200 mb-2">M.Tech Transportation Engineering and Management</li>
                                <li class="text-gray-800 dark:text-gray-200 mb-2">M.Tech Computer Network Engineering</li>
                                <li class="text-gray-800 dark:text-gray-200 mb-2">M.Tech Biomedical Signal Processing and Instrumentation</li>
                                <li class="text-gray-800 dark:text-gray-200 mb-2">M.Tech Power Electronics</li>
                                <li class="text-gray-800 dark:text-gray-200 mb-2">M.Tech Biochemical Engineering</li>
                            </ul>
                        </div>

                        <div>
                            <p className='text-gray-700 my-4'>In this college, they also offer Ph.D. Programs like:</p>
                            <ul class="list-disc list-inside p-4 bg-white dark:bg-gray-900 rounded-lg shadow-lg max-w-lg">
                                <li class="text-gray-800 dark:text-gray-200 mb-2">Civil Engineering</li>
                                <li class="text-gray-800 dark:text-gray-200 mb-2">Mechanical Engineering</li>
                                <li class="text-gray-800 dark:text-gray-200 mb-2">Electrical Engineering</li>
                                <li class="text-gray-800 dark:text-gray-200 mb-2">Electronics and Communication Engineering</li>
                            </ul>
                        </div>

                        <p className='text-gray-700 mt-8 mb-4'>
                            The College has one of the biggest understudy populaces among designing schools in Karnataka.
                            As of now, around 5000 understudies are seeking after their advanced education.
                            The scholarly execution of the understudies has been uncommon. Over 90% of the understudies secure the top of the line/qualification.
                            The Institution has an amazing Placement and Recruitment Cell. The Placement Center gives profession direction, pre-arrangement preparing in delicate aptitudes to empower understudies acquainted with the current corporate prerequisites.
                            In excess of 100 rumoured Core/IT/MNC organizations visit the grounds each year for enrolling understudies from different branches.
                            90% of the qualified understudies are getting set each year. The College has a voluminous modernised library and data focus, which obliges the necessities of understudies, examine researchers and personnel.
                        </p>

                        <p className='text-gray-700 my-4'>
                            The undergrad Programs of BMSCE have been granted the Autonomous Status by the Government/University from the scholarly year 2008-09 onward.
                            It is sure reflection on the scholastic perfection supported by the school.
                            Self-rule has given Academic Flexibility that is required to occasionally survey the course substance and systems obviously conveyance in the light of changing requests of the market.
                        </p>

                        <p className='text-gray-700 my-4'>
                            The Institution has very Qualified, Experienced and Dedicated workforce and staff inpiduals.
                            BMS College of Engineering (BMSCE) has the most developed research centers for functional preparing, best in class registering offices, open classrooms with great furniture and A/V helps, Wi-Fi grounds and 24×7 web offices in the offices and lodgings.
                            The Institution has Collaborative and Extension Activities by method for MOUs with different Governmental and Non-Governmental associations for cultivating Academic/Research/Innovation.
                        </p>

                        <p className='text-gray-700 my-4'>
                            A solid graduated class base of more than 35,000 the majority of them possessing desired positions in different Educational, Industrial, and Research Associations. The school is being positioned reliably among the best 20 designing universities in the nation by studies directed by different media sources.
                        </p>

                        <p className='text-gray-700 my-4'>
                            The mission of BMSCE Library is to look for, make, convey and save information and understanding and carry out the mission in a bound together network known as College. The library, in the help of the instructive and research mission of the school, is the nearby vault and the key door to momentum data and the academic record. In that capacity, it is at the same time accumulation and association for the present and future understudies and staff of the school.
                        </p>

                        <p className='text-gray-700 my-4'>
                            It is a data center point of the scholastic network and a focal point of unbridled scholarly request. A place where the past meets with the present and the street to what’s to come is covered with each possible attempted thought and tried hypothesis. The essential goal of the library is to offer a completely incorporated and dynamic condition suited for scholarly interests, study, and research for the understudies and staff, by enlarging customary print and electronic assets both nearby and worldwide advancements.
                        </p>

                        <div>
                            <h1 className='text-2xl text-blue-900 font-semibold'> Admission Procedure in BMS Engineering College   </h1>
                            <p className='text-gray-700 my-4'>Thousands of students from every part of the country and abroad apply for an Engineering seat in BMS college in Bangalore. You can take admission by an entrance exam and management quota.</p>
                            <p className='text-gray-700 my-4'>Here I will tell you the admission procedure through the entrance exam. You can take admission if you score well in any of the following entrance exams:</p>
                            <ul class="list-disc list-inside p-4 bg-white dark:bg-gray-900 rounded-lg shadow-lg max-w-md">
                                <li class="text-gray-800 dark:text-gray-200 mb-2">Management Quota - 20%</li>
                                <li class="text-gray-800 dark:text-gray-200 mb-2">KCET - 45%</li>
                                <li class="text-gray-800 dark:text-gray-200 mb-2">COMEDK - 35%</li>
                            </ul>
                            <p className='text-gray-700 my-4'> Fees structure is different for Management Quota, KCET and ComedK. </p>
                            <p className='text-gray-700 my-4'> K-CET is conducted by Karnataka Government via KEA or Karnataka Examination Authority. For KCET the candidate must be the domicile of Karnataka. You can apply if you have a domicile certificate. </p>
                            <p className='text-gray-700 my-4'> If you are able to score well then you have a chance to get admission to BMS College of Engineering.50% of the seats in the college are filled via the KCET entrance exam. </p>
                        </div>

                        <div>
                            <h1 className='text-2xl text-blue-900 font-semibold'> APPROVED MANAGEMENT FEE STRUCTURE FOR 2024-25   </h1>
                            <h4 className='text-xl lg:text-base lg:font-semibold my-2'> Admissions Open for the Management Quota Seats for UG Courses for B.M.S. College of
                                Engineering for the Academic Year 2024-25   </h4>

                            <div class="relative overflow-x-auto mb-4 rounded-lg shadow-lg">
                                <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                                    <thead class="text-xs text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400">
                                        <tr>
                                            <th scope="col" class="px-6 py-3 rounded-s-lg">
                                                Branch Name
                                            </th>

                                            <th scope="col" class="px-6 py-3 rounded-e-lg">
                                                Fee per Annum(in Lakhs)
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr class="bg-white dark:bg-gray-800">
                                            <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                                Chemical Engineering
                                            </th>
                                            <td class="px-6 py-4">
                                                2.0
                                            </td>
                                        </tr>
                                        <tr class="bg-white dark:bg-gray-800">
                                            <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                                Civil Engineering
                                            </th>
                                            <td class="px-6 py-4">
                                                3.0
                                            </td>
                                        </tr>
                                        <tr class="bg-white dark:bg-gray-800">
                                            <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                                Mechanical Engineering
                                            </th>
                                            <td class="px-6 py-4">
                                                3.5
                                            </td>
                                        </tr>
                                        <tr class="bg-white dark:bg-gray-800">
                                            <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                                Bio-Technology
                                            </th>
                                            <td class="px-6 py-4">
                                                4.0
                                            </td>
                                        </tr>
                                        <tr class="bg-white dark:bg-gray-800">
                                            <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                                Aerospace Engineering
                                            </th>
                                            <td class="px-6 py-4">
                                                4.0
                                            </td>
                                        </tr>
                                        <tr class="bg-white dark:bg-gray-800">
                                            <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                                Electrical & Electronics Engg.
                                            </th>
                                            <td class="px-6 py-4">
                                                4.5
                                            </td>
                                        </tr>
                                        <tr class="bg-white dark:bg-gray-800">
                                            <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                                Industrial Engineering & Mgmt
                                            </th>
                                            <td class="px-6 py-4">
                                                4.5
                                            </td>
                                        </tr>
                                        <tr class="bg-white dark:bg-gray-800">
                                            <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                                Electronics & Communication Engg.
                                            </th>
                                            <td class="px-6 py-4">
                                                6.0
                                            </td>
                                        </tr>
                                        <tr class="bg-white dark:bg-gray-800">
                                            <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                                Artificial Intelligence and Data Science
                                            </th>
                                            <td class="px-6 py-4">
                                                7.0
                                            </td>
                                        </tr>
                                        <tr class="bg-white dark:bg-gray-800">
                                            <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                                Artificial Intelligence and Machine Learning
                                            </th>
                                            <td class="px-6 py-4">
                                                7.5
                                            </td>
                                        </tr>
                                        <tr class="bg-white dark:bg-gray-800">
                                            <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                                Computer Science and Engineering (Data Science)
                                            </th>
                                            <td class="px-6 py-4">
                                                7.5
                                            </td>
                                        </tr>
                                        <tr class="bg-white dark:bg-gray-800">
                                            <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-wrap dark:text-white">
                                                Computer Science and Engineering (Internet of Things & Cyber Security including Blockchain Technology)
                                            </th>
                                            <td class="px-6 py-4">
                                                7.5
                                            </td>
                                        </tr>
                                        <tr class="bg-white dark:bg-gray-800">
                                            <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                                Computer Science & Business Systems
                                            </th>
                                            <td class="px-6 py-4">
                                                7.5
                                            </td>
                                        </tr>
                                        <tr class="bg-white dark:bg-gray-800">
                                            <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                                Computer Science and Engineering
                                            </th>
                                            <td class="px-6 py-4">
                                                10.0
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div id='contact-form' class="w-full lg:basis-2/6 mx-8 my-8">
                <form class="max-w-md mx-auto bg-white dark:bg-gray-900 rounded-lg shadow-lg max-w-lg px-8 py-4" onSubmit={onSubmit}>
                    <div className='text-3xl mb-4 pb-2 underline font-bold leading-none tracking-tight text-blue-900 md:text-2xl lg:text-3xl dark:text-white'>
                        Contact Now
                    </div>
                    <div class="relative z-0 w-full mb-5 group">
                        <input type="text" name="name" id="name" class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " required />
                        <label for="name" class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Name</label>
                    </div>
                    <div class="relative z-0 w-full mb-5 group">
                        <input type="email" name="email" id="email" class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " required />
                        <label for="email" class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Email address</label>
                    </div>
                    <div class="relative z-0 w-full mb-5 group">
                        <input type="tel" name="phone" id="phone" class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " required />
                        <label for="phone" class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Phone number</label>
                    </div>
                    <div class="relative z-0 w-full mb-5 group">
                        <input type="text" name="course" id="course" class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " required />
                        <label for="course" class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Course(M.Tech, BCA, MCA, BA, BMS)</label>
                    </div>
                    <div class="relative z-0 w-full mb-5 group">
                        <input type="text" name="branch" id="branch" class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " required />
                        <label for="branch" class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-9">Branch(Ex.Computer Science, Software, Civil, Mechanical)</label>
                    </div>
                    <button type="submit" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Submit</button>
                </form>
                <p className='my-4 text-center text-blue-700 font-bold'>{result}</p>

            </div>
        </div>

    )
}

export default Content